<template>
  <v-navigation-drawer
    v-if="data"
    :key="data.uuid"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 440"
    app
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div>
        <v-icon left>
          fa-kit {{ data?.uuid ? 'fa-light-building-pen' : 'fa-light-building-circle-plus' }}
        </v-icon>
        {{ data?.uuid ? data.name : "Create Client" }}
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        end
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      grow
      dark
    >
      <v-tab>
        <v-icon v-text="'fa-light fa-circle-info'" />
      </v-tab>
      <v-tab>
        <v-icon v-text="'fa-light fa-palette'" />
      </v-tab>
      <v-tab v-if="data.uuid">
        <v-icon v-text="'fa-light fa-file-invoice-dollar'" />
      </v-tab>
      <v-tab v-if="data.uuid">
        <v-icon v-text="'fa-light fa-puzzle-piece'" />
      </v-tab>
    </v-tabs>
    <v-form
      ref="clientForm"
      v-model="valid"
      @submit.prevent="updateClient"
    >
      <v-tabs-items v-model="tab">
        <v-container class="spy-0">
          <v-tab-item class="pt-4">
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="data.name"
                  :label="$t('name') | capitalize"
                  dense
                  :rules="rules.required"
                  :items="companies"
                  item-text="name"
                  item-value="name"
                  :search-input.sync="companySearch"
                  @change="populateCompany($event)"
                >
                  <template #item="{ item }">
                    <v-list-item-avatar rounded>
                      <v-img :src="item.logo" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name" />
                      <v-list-item-subtitle v-text="item.domain" />
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="data.address"
                  :label="$t('address') | capitalize"
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <location-filter
                  :value.sync="data.country"
                  label="Country"
                  type="countries"
                  :icon="false"
                  :multiple="false"
                  :small-chips="false"
                  :clearable="false"
                />
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="data.postcode"
                  :label="$t('postcode')"
                  dense
                  @keyup="verifyZip(data.postcode, data)"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="data.city"
                  :label="$t('city') | capitalize"
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <location-filter
                  :value.sync="data.state"
                  :label="$t('state') | capitalize"
                  :icon="false"
                  :multiple="false"
                  :small-chips="false"
                  :clearable="false"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="data.domain"
                  prepend-icon="fa-light fa-globe"
                  label="Domain"
                  type="url"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedContractDate"
                      :value="data.contract_date"
                      label="Contract Date"
                      prepend-icon="fa-light fa-calendar-day"
                      readonly
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="data.contract_date"
                    no-title
                    scrollable
                    reactive
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="data.services"
                  prepend-icon="fa-light fa-bell-concierge"
                  label="Services"
                  :items="$store.state.selects.services"
                  multiple
                  small-chips
                  deletable-chips
                  item-value="id"
                  item-text="name"
                  :rules="rules.service"
                  dense
                  required
                />
              </v-col>

              <v-col cols="12">
                <formulary-picker
                  :value.sync="data.formulary"
                />
              </v-col>

              <v-col
                v-if="data.all_meta"
                cols="12"
              >
                <v-autocomplete
                  v-model="data.all_meta.timezone"
                  :items="$moment.tz.names()"
                  prepend-icon="fa-light fa-clock"
                  label="Timezone for Reports"
                  type="client"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <tag-picker
                  :value.sync="data.tags"
                  type="client"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-file-input
                  v-model="data.icon_file"
                  prepend-icon="fa-light fa-image-polaroid"
                  label="Icon"
                  accept="image/*"
                  dense
                  show-size
                >
                  <template #append-outer>
                    <v-img
                      v-if="data.icon"
                      :src="data.icon"
                      class="mt-n1 ml-1"
                      width="150"
                      height="32"
                      contain
                    />
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="data.logo_file"
                  prepend-icon="fa-light fa-image"
                  label="Report Logo"
                  accept="image/*"
                  dense
                  show-size
                >
                  <template #append-outer>
                    <v-img
                      v-if="data.logo"
                      :src="data.logo"
                      class="mt-n3 ml-1"
                      width="150"
                      contain
                    />
                  </template>
                </v-file-input>
              </v-col>

              <template v-if="data.all_meta">
                <v-col
                  cols="12"
                  class="text-overline pb-0"
                >
                  Report Colors
                </v-col>
                <v-col cols="6">
                  <color-input
                    v-model="data.all_meta.report_color_primary"
                    label="Primary"
                  />
                </v-col>

                <v-col cols="6">
                  <color-input
                    v-model="data.all_meta.report_color_secondary"
                    label="Secondary"
                    dot
                  />
                </v-col>

                <v-col cols="6">
                  <color-input
                    v-model="data.all_meta.report_color_tertiary"
                    label="Tertiary"
                  />
                </v-col>

                <template v-if="data.services.includes(1) || data.services.includes(4) || data.services.includes(5) || data.services.filter((x) => [1,4,5].includes(x.id)).length > 0">
                  <v-col
                    cols="12"
                    class="text-overline pb-0"
                  >
                    OneChoice Report Options
                  </v-col>

                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <v-select
                      v-model="data.all_meta.reports_language"
                      :items="$languages"
                      prepend-icon="fa-light fa-language"
                      dense
                    />

                    <v-switch
                      v-model="data.all_meta.reports_all_matrix"
                      label="MedsMatrix on all OneChoice Reports"
                      prepend-icon="fa-light fa-grid"
                      class="my-0"
                      dense
                    />

                    <v-switch
                      v-model="data.all_meta.report_no_arkstone_logo"
                      label="Hide Arkstone Logo"
                      prepend-icon="fak fa-arkstone-logo"
                      color="error"
                      class="my-0"
                      dense
                    />

                    <v-switch
                      v-for="icon, section in {
                        'allergies': 'fa-light fa-allergies',
                        'infection_complexity': 'fa-light fa-gauge',
                        'infection_control': 'fa-light fa-biohazard',
                      }"
                      :key="section"
                      v-model="reports_hide_sections"
                      :value="section"
                      :label="'Hide ' + section.replace(/_/g, ' ') | capitalize"
                      :prepend-icon="icon"
                      color="error"
                      class="my-0"
                      dense
                    />
                  </v-col>
                </template>

                <template v-if="data.services.includes(2) || data.services.filter((x) => x.id == 2).length > 0">
                  <v-col
                    cols="12"
                    class="text-overline pb-0"
                  >
                    ASP Report Options
                  </v-col>

                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <v-switch
                      v-model="asp_report_options.hide_checklist"
                      label="Hide Checklists"
                      prepend-icon="fa-light fa-clipboard-list-check"
                      dense
                    />

                    <v-switch
                      v-model="asp_report_options.hide_not_tested"
                      label="Hide 'Not Tested' Pages"
                      prepend-icon="fa-light fa-flask-vial"
                      dense
                    />

                    <source-select
                      v-model="asp_report_options.hide_sources"
                      label="Hide Sources"
                      prepend-icon="fa-light fa-vial"
                      multiple
                      small-chips
                      deletable-chips
                      dense
                    />
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row v-if="data.all_meta">
              <v-col cols="12">
                <v-radio-group
                  v-model="pricingModel"
                  label="Model"
                  row
                  dense
                >
                  <v-radio
                    v-for="model in [
                      'tiers',
                      'tranches',
                      'fixed'
                    ]"
                    :key="model"
                    :label="model | capitalize"
                    :value="model"
                  />
                </v-radio-group>
                <template v-if="pricingModel == 'tranches'">
                  <v-data-table
                    :items="reports_price"
                    :headers="[
                      {
                        text: 'From',
                        value: 'lo',
                        sortable: false,
                      },
                      {
                        text: 'To',
                        value: 'hi',
                        sortable: false,
                      },
                      {
                        text: 'Price',
                        value: 'price',
                        sortable: false,
                      },
                      {
                        value: 'edit',
                        sortable: false,
                        align: 'right',
                      },
                    ]"
                    sort-by="lo"
                    hide-default-footer
                    disable-pagination
                    dense
                  >
                    <template #top>
                      <span class="text-caption mb-4">
                        Price per Report
                      </span>
                    </template>
                    <template #item.lo="{ item }">
                      <v-edit-dialog :return-value.sync="item.lo">
                        {{ item.lo }}
                        <template #input>
                          <v-text-field
                            v-model.number="item.lo"
                            label="From"
                          />
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.hi="{ item }">
                      <v-edit-dialog :return-value.sync="item.hi">
                        {{ item.hi }}
                        <template #input>
                          <v-text-field
                            v-model.number="item.hi"
                            label="To"
                          />
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.price="{ item }">
                      <v-edit-dialog :return-value.sync="item.price">
                        {{ item.price | currency }}
                        <template #input>
                          <v-text-field
                            v-model.number="item.price"
                            label="Price"
                            prefix="$"
                          />
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.edit="{ index }">
                      <v-btn
                        icon
                        small
                        @click="reports_price.splice(index, 1)"
                      >
                        <i class="fa-light fa-circle-minus" />
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-btn
                    icon
                    @click="
                      reports_price.push({
                        lo: '',
                        hi: '',
                        price: '',
                      })
                    "
                  >
                    <v-icon>fa-light fa-circle-plus</v-icon>
                  </v-btn>
                </template>
                <template
                  v-else-if="pricingModel == 'tiers'"
                >
                  <v-data-table
                    :items="reports_price"
                    :headers="[
                      {
                        text: 'Tier',
                        value: 'tier',
                        sortable: false,
                      },
                      {
                        text: 'Threshold',
                        value: 'threshold',
                        sortable: false,
                      },
                      {
                        text: 'Price',
                        value: 'price',
                        sortable: false,
                      },
                      {
                        value: 'edit',
                        sortable: false,
                        align: 'right',
                      },
                    ]"
                    hide-default-footer
                    disable-pagination
                    sort-by="threshold"
                    dense
                  >
                    <template #top>
                      <span class="text-caption mb-4">
                        Price per Report
                      </span>
                    </template>
                    <template #item.tier="{ item, index }">
                      <span class="text-caption">
                        {{ reports_price[index + 1] ? reports_price[index + 1]?.threshold - 1 + ' or fewer' : item.threshold + ' or more' }}
                      </span>
                    </template>
                    <template #item.threshold="{ item }">
                      <v-edit-dialog :return-value.sync="item.threshold">
                        {{ item.threshold }}
                        <template #input>
                          <v-text-field
                            :value="item.threshold"
                            label="Threshold"
                            @change="changeTier($event, item)"
                          />
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.price="{ item }">
                      <v-edit-dialog :return-value.sync="data.all_meta.reports_price[item.threshold]">
                        {{ item.price | currency }}
                        <template #input>
                          <v-text-field
                            v-model.number="data.all_meta.reports_price[item.threshold]"
                            label="Price"
                            prefix="$"
                          />
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.edit="{ item }">
                      <v-btn
                        icon
                        small
                        @click="$delete(data.all_meta.reports_price, item.threshold)"
                      >
                        <i class="fa-light fa-circle-minus" />
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-btn
                    icon
                    @click="
                      $set(
                        data.all_meta.reports_price,
                        Math.max(...Object.keys(data.all_meta.reports_price)) +
                          100,
                        25
                      )
                    "
                  >
                    <v-icon>fa-light fa-circle-plus</v-icon>
                  </v-btn>
                </template>
                <v-text-field
                  v-else-if="pricingModel == 'fixed'"
                  v-model.number="reports_price"
                  label="Price per Report"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.reports_price_culture"
                  label="Culture Reports"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.reports_price_negative"
                  label="Negative Reports"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.reports_price_matrix"
                  label="MedsMatrix PDF"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.reports_price_covid"
                  label="COVID only price"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.billing_monthly_minimum"
                  label="Monthly Minimum"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pt-0"
              >
                <v-checkbox
                  v-model.number="
                    data.all_meta.reports_threshold_ignore_negatives
                  "
                  dense
                >
                  <template #label>
                    <small>Thresholds Ignore Negatives</small>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <date-filter
                  :value.sync="data.all_meta.reports_promo_end_date"
                  label="Promo End Date"
                  :range="false"
                  :max-date="false"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="data.all_meta.reports_promo_price"
                  label="Promo price"
                  prefix="$"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="billing_invoice_items"
                  :headers="[
                    {
                      text: 'Line Item',
                      value: 'name',
                    },
                    {
                      text: 'Price',
                      value: 'price',
                    },
                    {
                      value: 'delete',
                      align: 'end',
                    },
                  ]"
                  title="Invoice Items"
                  :hide-default-header="!billing_invoice_items.length"
                  hide-default-footer
                  disable-pagination
                  no-data-text=""
                  dense
                  :class="{ 'mb-n10': !billing_invoice_items.length }"
                >
                  <template #top>
                    <div class="d-flex align-center">
                      <span class="text-caption">Additional Invoice Items</span>
                      <v-spacer />
                      <v-btn
                        icon
                        small
                        @click="$set(data.all_meta, 'billing_invoice_items', [...billing_invoice_items, { name: 'Item Name', price: 0 }])"
                      >
                        <v-icon small>
                          fa-light fa-circle-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template #item.name="{ item }">
                    <v-edit-dialog :return-value.sync="item.name">
                      {{ item.name }}
                      <template #input>
                        <v-text-field
                          v-model="item.name"
                          label="Line Item"
                        />
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template #item.price="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.price"
                      large
                    >
                      {{ item.price | currency }}
                      <template #input>
                        <v-text-field
                          v-model.number="item.price"
                          label="Price"
                          prefix="$"
                        />
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template #item.delete="{ index }">
                    <v-btn
                      icon
                      x-small
                      @click="billing_invoice_items.splice(index, 1)"
                    >
                      <v-icon x-small>
                        fal fa-circle-minus
                      </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="data.all_meta.billing_notes"
                  label="Billing Notes"
                  auto-grow
                  clearable
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="data.all_meta.billing_commission_notes"
                  label="Commission Notes"
                  auto-grow
                  clearable
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="data.uuid && data.all_meta">
            <v-text-field
              v-model.trim="data.api_token"
              :readonly="
                !$auth.check({ clients: 'integration' }) && data.has_api_token
              "
              :hint="data.has_api_token ? 'API Token Already Set' : null"
              :persistent-hint="true"
              :placeholder="
                data.has_api_token
                  ? '*************************************************'
                  : null
              "
              label="API Token"
              xclearable
              dense
              class="text-caption my-3"
            >
              <template slot="append">
                <v-btn
                  small
                  icon
                  tile
                  @click="data.api_token ? copyApiToken() : generateApiToken()"
                >
                  <v-icon
                    small
                  >
                    {{ data.api_token ? 'mdi-content-copy mdi-flip-h' : 'mdi-key' }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-divider />
            <v-text-field
              v-model="integration_details.lis"
              label="LIS"
            />
            <v-divider />
            <v-row>
              <v-col
                cols="12"
                class="pb-0 mb-n1"
              >
                <v-switch
                  :input-value="
                    data.all_meta.hasOwnProperty('reports_hl7_drive_outgoing')
                  "
                  :disabled="!$auth.check({ clients: 'integration' })"
                  label="HL7 via S3"
                  dense
                  @click="toggleMeta('hl7')"
                />
              </v-col>
              <template
                v-if="
                  data.all_meta.hasOwnProperty('reports_hl7_drive_outgoing')
                "
              >
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model.trim="data.all_meta.reports_hl7_drive_outgoing"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="S3 Bucket"
                    :search-input.sync="s3.search"
                    :items="s3.folders"
                    cache-items
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model.trim="data.all_meta.reports_hl7_template_outgoing"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Template"
                    dense
                  />
                </v-col>
              </template>
            </v-row>
            <v-divider class="mt-4" />
            <v-row>
              <v-col
                cols="12"
                class="pb-0 mb-n1"
              >
                <v-switch
                  :input-value="
                    data.all_meta.hasOwnProperty('reports_json_drive_outgoing')
                  "
                  :disabled="!$auth.check({ clients: 'integration' })"
                  label="JSON via S3"
                  dense
                  @click="toggleMeta('json')"
                />
              </v-col>
            </v-row>
            <template
              v-if="data.all_meta.hasOwnProperty('reports_json_drive_outgoing')"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model.trim="data.all_meta.reports_json_drive_outgoing"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="S3 Bucket"
                    :search-input="s3.search"
                    :items="s3.folders"
                    cache-items
                    dense
                  />
                </v-col>
              </v-row>
            </template>
            <v-divider class="mt-4" />
            <v-row>
              <v-col
                cols="12"
                class="pb-0 mb-n1"
              >
                <v-switch
                  :input-value="
                    data.all_meta.hasOwnProperty('reports_ftp_outgoing')
                  "
                  :disabled="!$auth.check({ clients: 'integration' })"
                  label="FTP"
                  dense
                  @click="toggleMeta('ftp')"
                />
              </v-col>
            </v-row>
            <template
              v-if="data.all_meta.hasOwnProperty('reports_ftp_outgoing')"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-select
                    v-model="data.all_meta.reports_ftp_outgoing.driver"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Driver"
                    :items="[
                      { value: 'sftp', text: 'SFTP' },
                      { value: 'ftp', text: 'FTP' },
                      { value: 'ftps', text: 'FTPS' },
                    ]"
                    dense
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-select
                    v-model="data.all_meta.reports_ftp_outgoing.format"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Format"
                    :items="[
                      { value: 'json', text: 'JSON' },
                      { value: 'hl7', text: 'HL7' },
                    ]"
                    dense
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model.number="data.all_meta.reports_ftp_outgoing.port"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Port"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="data.all_meta.reports_ftp_outgoing.host"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Host"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="data.all_meta.reports_ftp_outgoing.root"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Root"
                    prefix="/"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="data.all_meta.reports_ftp_outgoing.username"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Username"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="data.all_meta.reports_ftp_outgoing.password"
                    :type="showPassword ? 'text' : 'password'"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Password"
                    dense
                  >
                    <template slot="append">
                      <v-btn
                        icon
                        @click="showPassword = !showPassword"
                      >
                        <v-icon>
                          {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-divider class="mt-4" />
            <v-row>
              <v-col
                cols="12"
                class="pb-0 mb-n1"
              >
                <v-switch
                  :input-value="data.all_meta.hasOwnProperty('reports_webhook')"
                  :disabled="!$auth.check({ clients: 'integration' })"
                  label="Webhook"
                  dense
                  @click="toggleMeta('webhook')"
                />
              </v-col>
            </v-row>
            <template v-if="data.all_meta.hasOwnProperty('reports_webhook')">
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model.trim="data.all_meta.reports_webhook"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    label="Endpoint URL"
                    class="text-caption mb-0"
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <span class="text-caption"> Headers </span>
                  <vue-json-editor
                    v-model="data.all_meta.reports_webhook_headers"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    :show-btns="false"
                    mode="code"
                    label="Headers"
                    rows="2"
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <span class="text-caption"> Payload </span>
                  <vue-json-editor
                    v-model="data.all_meta.reports_webhook_payload"
                    :readonly="!$auth.check({ clients: 'integration' })"
                    :show-btns="false"
                    mode="code"
                    label="Payload"
                    dense
                  />
                </v-col>
              </v-row>
            </template>
            <v-divider class="mt-12" />
            <v-row>
              <v-col
                cols="12"
                class="text-overline mb-0 pb-0"
              >
                Settings
              </v-col>
              <v-col class="py-0">
                <v-switch
                  v-model="data.all_meta.reports_negative_dont_process"
                  label="Don't process negative reports"
                  dense
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-form>
    <template #append>
      <v-divider />
      <v-col class="pb-0">
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateClient"
        >
          <v-icon
            small
            left
          >
            fa-light fa-check
          </v-icon>
          {{ data?.uuid ? "Update" : "Create" }} Client
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          v-if="data.uuid && $auth.check({ clients: 'edit' })"
          color="error"
          block
          outlined
          @click="deleteClient"
        >
          <v-icon
            small
            left
          >
            fa-light fa-trash
          </v-icon>
          Delete Client
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
import cryptoRandomString from "crypto-random-string";

export default {
  components: {
    vueJsonEditor: () => import("vue-json-editor"),
    TagPicker: () => import("@/components/forms/TagPicker.vue"),
    FormularyPicker: () => import("@/components/forms/FormularyPicker.vue"),
    ColorInput: () => import("@/components/ColorInput.vue"),
    SourceSelect: () => import("@/components/source/SourceSelect.vue"),
    LocationFilter: () => import("@/components/tables/filters/LocationFilter.vue"),
    DateFilter: () => import('@/components/tables/filters/DateFilter.vue'),
  },
  props: {
    open: Boolean,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      companies: [],
      companySearch: null,
      tab: null,
      valid: false,
      loading: false,
      menu: null,
      rules: {
        required: [v => !!v || this.$t('required')],
        service: [v => !!v.length || "At least one service is required"],
      },
      services: [],
      reports_price_method: null,
      price_defaults: {
        tiers: {
          1: 25,
          501: 24,
          701: 23,
          901: 22,
          1101: 21,
          1301: 20,
          1501: 19,
          1701: 18,
          1901: 17,
          2101: 16,
          2301: 15,
          2501: 14,
          3251: 13,
          4001: 12,
        },
        tranches: [
          { lo: 1, hi: 500, price: 25 },
          { lo: 501, hi: 1000, price: 22.5 },
          { lo: 1001, hi: 1500, price: 20 },
          { lo: 1501, hi: 2000, price: 17.5 },
          { lo: 2001, hi: 1000000, price: 15 },
        ],
      },
      s3: {
        search: null,
        folders: [],
      },
      showPassword: false,
    }
  },
  computed: {
    formattedContractDate() {
      return this.data.contract_date
        ? this.$moment(this.data.contract_date).format("LL")
        : null;
    },
    pricingModel: {
      get() {
        if (this.reports_price_method) {
          return this.reports_price_method;
        } else {
          if (!isNaN(this.data.all_meta.reports_price)) {
            return "fixed";
          } else if (Array.isArray(this.data.all_meta.reports_price)) {
            return "tranches";
          } else {
            return "tiers";
          }
        }
      },
      set(val) {
        switch (val) {
          case "fixed":
            if (isNaN(this.reports_price)) {
              this.reports_price = "";
            }
            break;
          case "tiers":
            this.reports_price = this.price_defaults.tiers
            break;
          case "tranches":
            this.reports_price = this.price_defaults.tranches
            break;
        }
        this.reports_price_method = val;
      },
    },
    reports_price: {
      get() {
        if (this.reports_price_method === 'tiers' || typeof this.data.all_meta.reports_price === 'object') {
          return Object.entries(this.data.all_meta.reports_price).map(x => ({
            threshold: x[0],
            price: x[1],
          }))
        }
        return this.data.all_meta.reports_price;
      },
      set(val) {
        this.data.all_meta.reports_price = val;
      },
    },
    asp_report_options: {
      get() {
        return this.data.all_meta?.asp_report_options ?? {}
      },
      set(val) {
        this.data.all_meta.asp_report_options = val
      },
    },
    integration_details: {
      get() {
        return this.data.all_meta?.integration_details ?? {}
      },
      set(val) {
        this.data.all_meta.integration_details = val
      },
    },
    reports_hide_sections: {
      get() {
        return this.data.all_meta?.reports_hide_sections ?? []
      },
      set(val) {
        this.data.all_meta.reports_hide_sections = val
      },
    },
    billing_invoice_items: {
      get() {
        return this.data.all_meta?.billing_invoice_items ?? []
      },
      set(val) {
        this.data.all_meta.billing_invoice_items = val
      },
    },

  },
  watch : {
    companySearch(val) {
      if (val.length) {
        fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${val}`)
          .then(response => response.json())
          .then(data => {
            this.companies = data
          })
      }
    },
    's3.search' (val) {
      this.axios.get('admin/aws/buckets', {
        params : {
          search : val,
          folders: true,
        },
      }).then(response => {
        this.s3.folders = response.data.map(x => x.Key)
      })
    },
    'data.all_meta': {
      handler(val) {
        if (val?.reports_hl7_drive_outgoing) {
          this.s3.search = val.reports_hl7_drive_outgoing
        }
        if (val?.reports_json_drive_outgoing) {
          this.s3.search = val.reports_json_drive_outgoing
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.$store.state.selects.services.length) {
      this.axios
        .get("admin/services")
        .then((response) => {
          this.$store.state.selects.services = response.data;
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
    }
  },
  methods: {
    populateCompany(company) {
      this.$set(this.data, 'name', company.name || company)
      if (company.domain) {
        this.$set(this.data, 'domain', company.domain)
        fetch(`https://autocomplete.clearbit.com/v2/companies/find?domain=${company.domain}`, { headers: { Authorization: 'Basic c2tfN2ZiZTA3N2E3YTg4NzY2ZmY3ODdlYTNmOTAzNDgzOTE6' } })
          .then(response => response.json())
          .then(data => {
            this.$set(this.data, 'address', data.geo.streetAddress)
            this.$set(this.data, 'city', data.geo.city)
            this.$set(this.data, 'state', data.geo.stateCode)
            this.$set(this.data, 'postcode', data.geo.postalCode)
            this.$set(this.data, 'country', data.geo.countryCode)
          })
      }
    },
    updateClient() {
      this.$refs.clientForm.validate();
      if (this.valid) {
        this.loading = true;
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.data)) {
          if (value) {
            if (value instanceof Object || value instanceof Array) {
              formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          }
        }
        if (this.data.all_meta) {
          formData.delete("all_meta");
          formData.append("all_meta", JSON.stringify(this.data.all_meta));
        }
        if (this.data.icon_file?.name) {
          formData.append("icon_file", this.data.icon_file, this.data.icon_file.name);
        }
        if (this.data.logo_file?.name) {
          formData.append("logo_file", this.data.logo_file, this.data.logo_file.name);
        }
        if (this.data.uuid) {
          formData.append("_method", "patch");
        }
        this.axios
          .request({
            method: "post",
            url: this.data.uuid
              ? "admin/clients/" + this.data.uuid
              : "admin/clients",
            data: formData,
          })
          .then(() => {
            this.$root.$emit("fetch-clients");
            this.$emit("close-drawer");
            this.$toast.success("Client updated");
          })
          .catch((error) => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteClient() {
      this.$dialog
        .warning({
          text: "Do you really want to delete this client?",
          title: "Delete Client",
          icon: "fas fa-exclamation-triangle mr-2",
          actions: {
            true: {
              text: "Yes",
              color: "red",
            },
            false: "No",
          },
        })
        .then((res) => {
          if (res) {
            this.axios
              .delete("admin/clients/" + this.data.uuid)
              .then(() => {
                const index = this.clients.findIndex(
                  (x) => x.uuid === this.data.uuid,
                );
                this.$delete(this.clients, index);
                this.$toast.success("Client deleted");
              })
              .catch((error) => {
                if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
              });
          }
        });
    },
    generateApiToken() {
      this.$set(
        this.data,
        "api_token",
        cryptoRandomString({ length: 60, type: "base64" }),
      );
      this.copyApiToken();
    },
    copyApiToken() {
      this.$clipboard(this.data.api_token);
      this.$toast.copy("API Token copied to clipboard");
    },
    toggleMeta(key) {
      switch (key) {
        case "hl7":
          if ("reports_hl7_drive_outgoing" in this.data.all_meta) {
            this.$delete(this.data.all_meta, "reports_hl7_drive_outgoing");
          } else {
            this.$set(this.data.all_meta, "reports_hl7_drive_outgoing", "");
          }
          break;
        case "json":
          if ("reports_json_drive_outgoing" in this.data.all_meta) {
            this.$delete(this.data.all_meta, "reports_json_drive_outgoing");
          } else {
            this.$set(this.data.all_meta, "reports_json_drive_outgoing", "");
          }
          break;
        case "ftp":
          if ("reports_ftp_outgoing" in this.data.all_meta) {
            this.$delete(this.data.all_meta, "reports_ftp_outgoing");
          } else {
            this.$set(this.data.all_meta, "reports_ftp_outgoing", {});
          }
          break;
        case "webhook":
          if ("reports_webhook" in this.data.all_meta) {
            this.$delete(this.data.all_meta, "reports_webhook");
          } else {
            this.$set(this.data.all_meta, "reports_webhook", "");
          }
          break;
      }
    },
    changeTier($event, item) {
      this.$delete(this.data.all_meta.reports_price, item.threshold)
      this.$set(this.data.all_meta.reports_price, $event, item.price)
    },
    verifyZip (zip, field) {
      if (zip?.length == 5) {
       fetch(`https://ziptasticapi.com/${zip}`)
          .then(response => response.json())
          .then(data => {
            this.$set(field, 'city', this.$options.filters.capitalize(data.city))
            this.$set(field, 'state', data.state)
          })
      }
    },
  },
};
</script>
<style>
div.jsoneditor-menu {
  display: none;
}
</style>
